<template>
  <div>
    <BaseDialog
      :dialog="dialog"
      :title="`${$t('content.share_sample')} ${idflNumber}`"
      :subTitle="$t('content.share_st')"
      width="60%"
      @close="onClose"
    >
      <div class="my-5">
        <v-combobox
          :label="$t('content.share_lb')"
          outlined
          class="justify-end rounded-lg"
          chips
          clearable
          multiple
          deletable-chips
          :hide-details="true"
          v-model="userShareList"
          :delimiters="[', ', ',', ';', ' ']"
        >
          <template #append>
            <v-btn
              :icon="$vuetify.breakpoint.smAndDown"
              color="primary"
              :loading="loadingShare"
              :disabled="!userShareList[0]"
              class="share-button"
              depressed
              @click="shareItems"
            >
              <v-icon v-if="$vuetify.breakpoint.smAndDown">
                mdi-send
              </v-icon>
              <span v-else> {{ $t('content.share_now') }}</span>
            </v-btn>
          </template>
        </v-combobox>
      </div>

      <v-list nav class="px-0">
        <v-list-item v-for="item in userList" :key="item.id" class="custom-list mb-4">
          <v-list-item-content>
            <v-list-item-title class="title" v-text="item.address" />
          </v-list-item-content>
          <v-list-item-action>
            <BaseActionButton
              :disabled="loadingShare"
              :tooltipsText="$t('content.share')"
              :selectedItem="item"
              @click="shareItem(item.address)"
            >
              <SvgIcon icon="icon-share" width="20px" height="20px" />
            </BaseActionButton>
          </v-list-item-action>
          <v-list-item-action>
            <BaseActionButton
              :disabled="loadingShare == item"
              :tooltipsText="$t('content.delete')"
              v-if = "item.deletable"
              @click="removeOwner(item.id)"
            >
              <SvgIcon icon="icon-delete" width="20px" height="20px" />
            </BaseActionButton>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </BaseDialog>

    <v-dialog max-width="200px" v-model="loadingShare">
      <v-card flat class="pa-4 text-center">
        <v-progress-circular v-if="loadingShare" :size="100" color="primary-light" indeterminate />
        <div class="mt-4">
          {{ $t('content.please_wait') }}
        </div>
      </v-card>
    </v-dialog>

    <BaseAlert ref="alert" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseActionButton from '@/components/base/BaseActionButton';
import BaseAlert from '@/components/base/BaseAlert';
import BaseDialog from '@/components/base/BaseDialog';

export default {
  name: 'SampleShare',
  components: {
    BaseActionButton,
    BaseAlert,
    BaseDialog,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    idflNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingShare: false,
      successShare: 0,
      userShareList: [],
      userList: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'Auth/user',
      sampleDetail: 'SampleTracking/sampleDetail',
      workOrderOwners: 'SampleTracking/workOrderOwners',
    }),
  },
  watch: {
    workOrderOwners() {
      this.userList = this.workOrderOwners.map(work_order => {
        return {
          id: work_order.id,
          address: work_order.owner.address,
          deletable: (this.user.id==work_order['shared_by']||this.user.master_ids.includes(this.sampleDetail.company_id))?true:false
        };
      });
    },
  },
  methods: {
    async shareItems() {
      if (
        await this.$refs.alert.open({
          type: 'confirm',
          title: `${this.$t('content.share_sample')}`,
          message: `${this.$t('content.sure')} ${this.$t('content.sample', {
            sample: this.idflNumber,
          })}${this.userShareList.length === 1 ? ' ' + this.$t('content.to') + ' ' + this.userShareList[0] : ''}`,
          cancelBtn: true,
          icon: 'send',
        })
      ) {
        this.loadingShare = true;
        for (let i = 0; i < this.userShareList.length; i++) {
          await this.createWorkOrderOwner(this.userShareList[i]);
        }
        if (this.successShare > 0) {
          this.$refs.alert.open({
            type: 'confirm',
            title: `${this.$t('form.success')}`,
            message: `${this.$t('form.congrats')} ${this.$t('content.sample', {
              sample: this.idflNumber,
            })}`,
            icon: 'success',
            btnAgreeText: 'OK',
          });
        }
        this.reFetchOwner();
        this.successShare = 0;
        this.userShareList = [];
        this.loadingShare = false;
      }
    },
    shareItem(address) {
      if (this.userShareList.length > 0) this.userShareList = [];
      this.userShareList.push(address);
      this.shareItems();
    },
    async createWorkOrderOwner(address) {
      const { id: object_id, content_type } = this.sampleDetail;
      const owner = { address };
      const payload = {
        object_id,
        content_type,
        owner,
        shared_by: this.user.id,
      };
      await this.$store
        .dispatch('SampleTracking/createWorkOrderOwner', payload)
        .then(() => {
          this.successShare++;
          this.$toast.success(`${this.$t('content.success_share')} ${payload.owner.address}`);
        })
        .catch(err => this.$toast.error(`${payload.owner.address}. ` + err.message))
        .finally(() => (this.loadingShare = false));
    },
    reFetchOwner() {
      const { id: object_id, content_type } = this.sampleDetail;
      const param = { object_id, content_type };
      this.$store
        .dispatch('SampleTracking/getWorkOrderOwner', param)
        .then(() => (this.dialogShare = true))
        .catch(err => this.$toast.error(err.message));
    },
    onClose() {
      this.$emit('close');
      this.userShareList = [];
      setTimeout(() => {
        this.$store.commit('SampleTracking/RESET_WORK_ORDER_OWNERS');
      }, 300);
    },
    async removeOwner(itemID){
      if (
        await this.$refs.alert.open({
          type: 'confirm',
          title: `${this.$t('content.unshare_title')}`,
          message: `${this.$t('content.unshare_message')} `,
          cancelBtn: false,
          btnAgreeText: `${this.$t('content.revoke')}`,
          icon: 'delete',
        })
      ){
      const payload = {
        pk: itemID,
      }
      this.$store
        .dispatch('SampleTracking/removeWorkOrderOwner', payload)
        .then(()=>{
          this.reFetchOwner()
        })
        .catch(error => this.$store.dispatch('exceptionHandler/error', error));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.share-button {
  text-transform: none;
  margin-top: -8 px;
}

::v-deep .v-input__append-inner {
  margin-top: 14px;

  .v-input__icon--clear {
    padding-top: 16px;
  }
}

::v-deep .v-select__slot {
  .v-label {
    top: 25px;
    font-size: 14px;
  }

  .v-label--active {
    transform: translateY(-31px) scale(0.75);
  }
}

::v-deep .v-select__selections {
  .v-chip {
    background-color: #dfecf6 !important;
    color: #0060af !important;
    border-radius: 4px;

    .v-chip__content {
      .v-icon {
        color: #0060af !important;
      }
    }
  }
}
</style>
