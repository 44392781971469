<template>
  <v-data-table
    dense
    hide-default-footer
    disable-pagination
    :headers="tableHeaders"
    :items="sampleDetailTestOrder"
    :loading="loading"
    class="custom-table type-2"
  >
    <template #[`item.test`]="{value, item}">
      <div
        :class="{
          'py-3': item.care_instruction || item.package_order || item.sample_section,
        }"
      >
        <span
          :class="{
            'b-text': item.care_instruction || item.package_order || item.sample_section,
          }"
          >{{ value }}</span
        >
        <span class="c-text" v-if="item.care_instruction"><br />{{ item.care_instruction }}</span>
        <span class="c-text" v-if="item.package_order"><br />{{ item.package_order }}</span>
        <span class="c-text" v-if="item.sample_section"><br />{{ item.sample_section }}</span>
      </div>
    </template>
    <template #[`item.expected_completion_date`]="{ value }">
      {{ utilityMixin_formatDateTime(value) }}
    </template>
    <template #[`item.actions`]="{ item, index }">
      <template v-if="index == 0">
        <Popper
          :disabled="!popperShow"
          :force-show="popperShow"
          :trigger="popperTrigger"
          :options="{ placement: 'top' }"
          transition="fade"
          enter-active-class="fade-enter-active"
          leave-active-class="fade-leave-active"
        >
          <v-btn slot="reference" icon :disabled="hasExtraInfo(item)" @click="onDetail(item, 'extra')">
            <SvgIcon icon="icon-info" :class="{ 'opacity-btn': hasExtraInfo(item) }" />
          </v-btn>

          <div class="popper extra-popover without-arrow">
            <h1>Button Extra Info</h1>
            <p class="pa-5 mb-0">
              This button contains complete information about <br />
              the test orders including test modifications, <br />
              additional samples, and options.
              <v-checkbox @change="onHiddenInfo" label="Don't show this again" hide-details />
            </p>
          </div>
        </Popper>
      </template>

      <template v-else>
        <BaseActionButton
          :tooltipsText="$t('content.extra_information')"
          :disabled="hasExtraInfo(item)"
          @click="onDetail(item, 'extra')"
        >
          <SvgIcon icon="icon-info" :class="{ 'opacity-btn': hasExtraInfo(item) }" />
        </BaseActionButton>
      </template>

      <BaseActionButton :tooltipsText="$t('content.compare')" v-if="history" @click="onCompare(item.id, item.test_id)">
        <SvgIcon icon="icon-retest" />
      </BaseActionButton>
      <BaseActionButton :tooltipsText="$t('content.retest')" v-if="withRetest" @click="onRetest(item)">
        <SvgIcon icon="icon-compare" />
      </BaseActionButton>
    </template>
    <template #no-data>
      <BaseNoData :message="$t('content.no_test_order_data')" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { utilityMixin } from '@/mixins';

export default {
  name: 'SampleDetailTableOrder',
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    history: {
      type: Boolean,
      default: false,
    },
    withRetest: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [utilityMixin],
  data: () => ({
    hasReadInfo: Boolean(localStorage.getItem('hasReadInfo')),
  }),
  computed: {
    ...mapGetters({
      sampleDetailTestOrder: 'SampleTracking/sampleDetailTestOrder',
    }),
    tableHeaders() {
      return [
        { text: this.$t('content.tests'), value: 'test' },
        { text: this.$t('content.expc_due_date'), value: 'expected_completion_date', width: 220 },
        { text: this.$t('content.actions'), value: 'actions', sortable: false, width: 150 },
      ];
    },
    popperShow() {
      if (this.popperHide) {
        return false;
      }
      let status = !this.hasReadInfo;
      return !['xs', 'sm'].includes(this.$vuetify.breakpoint.name) ? status : status && !this.showSidebar;
    },
    popperTrigger() {
      return !['xs', 'sm'].includes(this.$vuetify.breakpoint.name) ? 'click' : 'hover';
    },
  },
  methods: {
    onHiddenInfo(val) {
      if (val) {
        this.hasReadInfo = true;
        localStorage.setItem('hasReadInfo', 1);
      }
    },
    hasExtraInfo(item) {
      if (item.additional_samples.length > 0 || item.test_modifications || item.options.length > 0) return false;
      else return true;
    },
    onDetail(item, mode) {
      this.$emit('detail', { item, mode });
    },
    onRetest(item) {
      this.$emit('retest', item);
    },
    onCompare(id, testId) {
      this.$emit('compare', { id, testId });
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity-btn {
  opacity: 0.4 !important;
}
</style>
