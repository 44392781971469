<template>
  <div>
    <BaseDialog
      :dialog="value"
      :title="$t('form.retest_sample')"
      :subTitle="$t('content.retest_st')"
      @close="onClose"
      width="50%"
    >
      <BaseFormGroup name="Reason" :validator="$v.reason">
        <template slot-scope="{ attrs }">
          <div class="text-area-container pa-3">
            <BaseLabel :title="$t('form.reason')" />
            <v-textarea
              v-model="reason"
              v-bind="attrs"
              :placeholder="$t('form.reason')"
              auto-grow
              outlined
              tabindex="1"
              class="rounded-lg"
              rows="3"
              row-height="30"
              hide-details="auto"
              @input="$v.reason.$touch()"
              @blur="$v.reason.$touch()"
            />
          </div>
        </template>
      </BaseFormGroup>
      <div class="d-flex justify-center mt-4">
        <v-btn color="no-transform button-cancel dialog-button mr-4" depressed :disabled="sending" @click="onClose">
          {{ $t('content.cancel') }}
        </v-btn>
        <v-btn color="primary no-transform dialog-button" depressed :loading="sending" :disabled="$v.$invalid" @click="onSubmit">
          {{ $t('form.submit') }}
        </v-btn>
      </div>
    </BaseDialog>
    <BaseAlert ref="alert" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import BaseDialog from '@/components/base/BaseDialog';
import BaseAlert from '@/components/base/BaseAlert';

export default {
  name: 'SampleDetailDialogRetest',
  components: {
    BaseDialog,
    BaseAlert,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    testOrder: {
      type: Object,
      default: null,
    },
  },
  validations: {
    reason: { required },
  },
  data: () => ({
    reason: '',
    sending: false,
  }),
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      if (
        await this.$refs.alert.open({
          type: 'confirm',
          title: this.$t('content.retest_cnf'),
          message: this.$t('form.retest_info'),
          icon: 'retest',
          btnAgreeText: this.$t('account.yes'),
          btnCancelText: this.$t('account.no'),
          cancelBtn: true,
        })
      ) {
        this.sending = true;
        const { id: test_order_id } = this.testOrder;
        const reason = this.reason;
        const payload = { test_order_id, reason };
        this.$store
          .dispatch('SampleTracking/testOrderRetestRequest', payload)
          .then(async () => {
            if (
              await this.$refs.alert.open({
                type: 'confirm',
                title: this.$t('form.success'),
                message: this.$t('content.retest_sccs'),
                icon: 'success',
                btnAgreeText: this.$t('content.ok'),
              })
            ) {
              this.onClose();
            }
          })
          .catch(err => this.$toast.error(err.message))
          .finally(() => (this.sending = false));
      }
    },
    onClose() {
      this.reason = '';
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.mn-color {
  color: #16325c;
}

.c-title {
  font-family: Arial;
  font-size: 17px;
  font-weight: bold;
}

.txt {
  font-family: ArialMT;
  font-size: 15px;

  a {
    text-decoration: none;
    color: #0084f4;
  }
}

.t-title {
  color: #617188 !important;
  font-family: ArialMT;
  font-size: 13px;
}

.dialog-button {
  font-size: 16px;
}

.button-cancel {
  background: transparent !important;
  border: 1px solid #0060af;
  color: #0060af;
}

.text-area-container {
  background: #dfecf6;
  border-radius: 8px;
}

::v-deep .v-btn {
  text-transform: none !important;
  font-size: 16px !important;
  border-radius: 8px !important;
}

::v-deep .v-input > .v-input__control > .v-input__slot {
  background: #fff;
}
</style>
