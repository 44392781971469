<template>
  <v-data-table
    dense
    hide-default-footer
    disable-pagination
    :headers="tableHeaders"
    :items="sampleDetailComparison"
    :loading="loading"
    class="custom-table type-2"
  >
    <template #no-data>
      <BaseNoData :message="$t('content.no_evaluation_comparison_data')" />
    </template>
    <template #[`item.evaluation`]="{value, item}">
      <div :class="{ 'py-2': item.sample_section }">
        <span :class="{ 'b-text': item.sample_section }">{{ value }}</span>
        <span class="c-text" v-if="item.sample_section"><br />{{ item.sample_section }}</span>
      </div>
    </template>
    <template #[`item.compared_samples`]="{ value }">
      <v-expansion-panels accordion flat v-if="value.length > 1" class="expansion-title">
        <v-expansion-panel class="expansion-panel">
          <v-expansion-panel-header class="pl-0">
            {{ value[0] }}
            <template #actions>
              <v-icon color="blue lighten-1">
                mdi-menu-down
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pl-0" v-for="(item, index) in value.slice(1)" :key="index">
            {{ item }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels accordion flat v-else>
        <v-expansion-panel class="expansion-panel">
          <v-expansion-panel-header hide-actions class="pl-0">
            {{ value[0] }}
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SampleDetailTableComparison',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      sampleDetailComparison: 'SampleTracking/sampleDetailComparison',
    }),
    tableHeaders() {
      return [
        {
          text: this.$t('content.evaluation'),
          value: 'evaluation',
        },
        {
          text: this.$t('content.compared_samples'),
          value: 'compared_samples',
        },
      ];
    },
  },
};
</script>
