var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"custom-table type-2",attrs:{"dense":"","hide-default-footer":"","disable-pagination":"","headers":_vm.tableHeaders,"items":_vm.sampleDetailTestOrder,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.test",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{class:{
        'py-3': item.care_instruction || item.package_order || item.sample_section,
      }},[_c('span',{class:{
          'b-text': item.care_instruction || item.package_order || item.sample_section,
        }},[_vm._v(_vm._s(value))]),(item.care_instruction)?_c('span',{staticClass:"c-text"},[_c('br'),_vm._v(_vm._s(item.care_instruction))]):_vm._e(),(item.package_order)?_c('span',{staticClass:"c-text"},[_c('br'),_vm._v(_vm._s(item.package_order))]):_vm._e(),(item.sample_section)?_c('span',{staticClass:"c-text"},[_c('br'),_vm._v(_vm._s(item.sample_section))]):_vm._e()])]}},{key:"item.expected_completion_date",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.utilityMixin_formatDateTime(value))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(index == 0)?[_c('Popper',{attrs:{"disabled":!_vm.popperShow,"force-show":_vm.popperShow,"trigger":_vm.popperTrigger,"options":{ placement: 'top' },"transition":"fade","enter-active-class":"fade-enter-active","leave-active-class":"fade-leave-active"}},[_c('v-btn',{attrs:{"slot":"reference","icon":"","disabled":_vm.hasExtraInfo(item)},on:{"click":function($event){return _vm.onDetail(item, 'extra')}},slot:"reference"},[_c('SvgIcon',{class:{ 'opacity-btn': _vm.hasExtraInfo(item) },attrs:{"icon":"icon-info"}})],1),_c('div',{staticClass:"popper extra-popover without-arrow"},[_c('h1',[_vm._v("Button Extra Info")]),_c('p',{staticClass:"pa-5 mb-0"},[_vm._v(" This button contains complete information about "),_c('br'),_vm._v(" the test orders including test modifications, "),_c('br'),_vm._v(" additional samples, and options. "),_c('v-checkbox',{attrs:{"label":"Don't show this again","hide-details":""},on:{"change":_vm.onHiddenInfo}})],1)])],1)]:[_c('BaseActionButton',{attrs:{"tooltipsText":_vm.$t('content.extra_information'),"disabled":_vm.hasExtraInfo(item)},on:{"click":function($event){return _vm.onDetail(item, 'extra')}}},[_c('SvgIcon',{class:{ 'opacity-btn': _vm.hasExtraInfo(item) },attrs:{"icon":"icon-info"}})],1)],(_vm.history)?_c('BaseActionButton',{attrs:{"tooltipsText":_vm.$t('content.compare')},on:{"click":function($event){return _vm.onCompare(item.id, item.test_id)}}},[_c('SvgIcon',{attrs:{"icon":"icon-retest"}})],1):_vm._e(),(_vm.withRetest)?_c('BaseActionButton',{attrs:{"tooltipsText":_vm.$t('content.retest')},on:{"click":function($event){return _vm.onRetest(item)}}},[_c('SvgIcon',{attrs:{"icon":"icon-compare"}})],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('BaseNoData',{attrs:{"message":_vm.$t('content.no_test_order_data')}})]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }