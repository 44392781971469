<template>
  <BaseDialog
    :dialog="value"
    :title="$t('content.t_ship_track_info')"
    :subTitle="$t('content.st_ship_track_info')"
    width="50%"
    @close="onClose"
  >
    <v-card
      elevation="0"
      color="indigo lighten-5"
      class="rounded-lg pb-10 mb-5"
      v-for="(item, index) in shippingItems"
      :key="index"
    >
      <v-card-title class="c-title mn-color">
        {{ $t('content.sample', { sample: item.item_shipped }) }}
      </v-card-title>
      <div class="px-4">
        <v-row v-for="(label, index) in Object.keys(labels)" :key="index">
          <v-col cols="4" class="txt t-title mn-color py-2">
            {{ $t(labels[label]) }}
          </v-col>
          <v-col cols="8" class="txt mn-color py-2">
            {{ item[label] || '-' }}
          </v-col>
        </v-row>
      </div>
    </v-card>
  </BaseDialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SampleDetailDialogShipment',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    shipmentTrackInfo: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      shippingItems: 'SampleTracking/shippingItems',
    }),
  },
  data: () => ({
    labels: {
      courier: 'content.shipment_by',
      tracking_number: 'content.tracking_number',
      link: 'content.link',
    },
  }),
  methods: {
    onClose() {
      this.$emit('input', false);
    },
  },
  destroyed() {
    this.$store.commit('SampleTracking/RESET_SHIPPING_ITEMS');
  },
};
</script>

<style lang="scss" scoped>
.mn-color {
  color: #16325c;
}

.c-title {
  font-family: Arial;
  font-size: 17px;
  font-weight: bold;
}

.txt {
  font-family: ArialMT;
  font-size: 15px;

  a {
    text-decoration: none;
    color: #0084f4;
  }
}

.t-title {
  color: #617188 !important;
  font-family: ArialMT;
  font-size: 13px;
}
</style>
