<template>
  <div v-if="deliverable">
    <BaseDialog :dialog="dialog" :title="dialogTitle" :subTitle="$t(subTitle)" width="90%" @close="onClose">
      <div class="my-5">
        <v-combobox
          :label="$t('content.share_label')"
          outlined
          class="justify-end rounded-lg"
          chips
          clearable
          multiple
          deletable-chips
          v-model="userShareList"
          :delimiters="[', ', ',', ';', ' ']"
          :hide-details="true"
        >
          <template #append>
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  :icon="$vuetify.breakpoint.smAndDown"
                  :loading="createOwnerLoading"
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!userShareList[0]"
                  class="share-button"
                >
                  <v-icon v-if="$vuetify.breakpoint.smAndDown">
                    mdi-send
                  </v-icon>
                  <span v-else>{{ $t('content.share_now') }}</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in shareType" :key="index">
                  <v-btn text @click="createOwner(item)" class="no-transform">
                    {{ item.title }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-combobox>
      </div>

      <v-skeleton-loader v-if="loading" type="table-heading, table-heading, table-heading, table-heading, table-heading" />
      <v-list v-else nav class="px-0">
        <v-list-item v-for="item in userList" :key="item.id" class="custom-list mb-4">
          <v-list-item-content>
            <v-list-item-title class="title" v-text="item.address" />
          </v-list-item-content>
          <v-list-item-action>
            <BaseActionButton
              :disabled="loadingShare == item"
              :tooltipsText="$t('content.share')"
              :withDropdown="true"
              :dropdownItems="shareTypeAccess"
              :selectedItem="item"
              @clickDropdown="shareItem"
            >
              <SvgIcon icon="icon-share" width="20px" height="20px" />
            </BaseActionButton>
          </v-list-item-action>
          <v-list-item-action v-if='item.deletable'>
            <BaseActionButton
              :disabled="loadingShare == item"
              :tooltipsText="$t('content.delete')"
              @click="onDelete(item.id)"
            >
              <SvgIcon icon="icon-delete" width="20px" height="20px" />
            </BaseActionButton>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </BaseDialog>

    <v-dialog max-width="200px" v-model="loadingShare">
      <v-card flat class="pa-4 text-center">
        <v-progress-circular v-if="loadingShare" :size="100" color="primary-light" indeterminate />
        <div class="mt-4">{{ $t('content.please_wait') }}</div>
      </v-card>
    </v-dialog>
    <BaseAlert ref="alert" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DeliverableDialogShare',
  props: {
    title: {
      type: String,
      default: 'content.share_deliv',
    },
    subTitle: {
      type: String,
      default: 'content.share_sub',
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    deliverable: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    createOwnerLoading: false,
    loadingShare: false,
    selectedUser: null,
    userShareList: [],
    userList: [],
    object_id: null,
    dialogDelete: false,
    deletingItem: null,
    deletingObjectId: 0
  }),
  computed: {
    ...mapGetters({
      user: 'Auth/user',
    }),
    dialogTitle() {
      const name = this.deliverable?.file_name ?? this.deliverable?.name ?? '';
      return this.$t('content.share_deliv') + ' - ' + name;
    },
    shareType() {
      return [
        { title: `${this.$t('content.share')} PDF`, action: 'pdf' },
        { title: `${this.$t('content.share')} Link`, action: 'link' },
      ];
    },
    shareTypeAccess() {
      return [{ title: `${this.$t('content.share')} PDF`, action: 'pdf' }];
    },
  },
  watch: {
    dialog(val) {
      if (val === true) {
        this.userList = [];
        this.fetchUsersByDeliverable();
      }
    },
  },
  methods: {
    async shareItem(item) {
      if (
        await this.$refs.alert.open({
          type: 'confirm',
          title: `${this.$t('content.share_deliv')}`,
          message: `${this.$t('content.sure')} ${this.deliverable.file_name} ${this.$t('content.to')} ${
            item.selectedItem.address
          } ?`,
          cancelBtn: true,
          icon: 'send',
        })
      ) {
        this.loadingShare = true;
        this.fetchDeliverableEmailSend(item.selectedItem.address);
      }
    },
    onClose() {
      this.$emit('close');
      this.userShareList = [];
    },
    async onDelete(itemID){
      if (
        await this.$refs.alert.open({
          type: 'confirm',
          title: `${this.$t('content.unshare_title')}`,
          message: `${this.$t('content.unshare_message')} `,
          cancelBtn: false,
          btnAgreeText: `${this.$t('content.revoke')}`,
          icon: 'delete',
        })
      ){
        const payload = {
          pk: itemID,
        }
        this.$store
          .dispatch('DeliverableCenter/removeDeliverableOwner', payload)
          .then(()=>{
            this.fetchUsersByDeliverable();
          })
          .catch(error => this.$store.dispatch('exceptionHandler/error', error));
      }
    },
    fetchUsersByDeliverable() {
      this.loading = true;
      const params = {
        object_id: this.deliverable.id,
        content_type: this.deliverable.content_type_id,
      };
      this.$store
        .dispatch('DeliverableCenter/fetchUsersDeliverable', params)
        .then(data => {
          const ownerList = data.map( data => ({
            id:data['id'],
            address: data['owner'].address ,
            deletable: (this.user.id==data['shared_by']||this.user.master_ids.includes(this.deliverable.owner_company))?true:false
          }));
          this.userList = ownerList;
          this.object_id = this.deliverable.id;
        })
        .catch(error => this.$store.dispatch('exceptionHandler/error', error))
        .finally(() => (this.loading = false));
    },
    async createOwner(item) {
      const tempSelectedItem = this.userShareList;
      if (item.action === 'link') {
        if (
          await this.$refs.alert.open({
            type: 'confirm',
            title: `${this.$t('content.share_deliv')}`,
            message: `${this.$t('content.sure')}  ${this.deliverable.file_name}`,
            cancelBtn: true,
            icon: 'send',
          })
        ) {
          this.createOwnerLoading = true;
          if (tempSelectedItem.length < 1) {
            this.createOwnerLoading = false;
            this.$toast.error(`${this.$t('content.email_invalid')}`);
          }
          for (let index = 0; index < tempSelectedItem.length; index++) {
            this.fetchCreateOwner(tempSelectedItem[index]);
          }
        }
      } else {
        if (
          await this.$refs.alert.open({
            type: 'confirm',
            title: `${this.$t('content.share_deliv')}`,
            message: `${this.$t('content.sure')}  ${this.deliverable.file_name}`,
            cancelBtn: true,
            icon: 'send',
          })
        ) {
          this.createOwnerLoading = true;
          if (tempSelectedItem.length < 1) {
            this.createOwnerLoading = false;
            this.$toast.error(`${this.$t('content.email_invalid')}`);
          }
          for (let index = 0; index < tempSelectedItem.length; index++) {
            this.fetchDeliverableEmailSend(tempSelectedItem[index]);
          }
        }
      }
    },
    fetchCreateOwner(item) {
      const payload = {
        object_id: this.object_id,
        content_type: this.deliverable.content_type_id,
        owner: { address: item },
        shared_by: this.user.id,
      };
      this.$store
        .dispatch('DeliverableCenter/createDeliverableOwner', payload)

        .then(() => {
          if (
            this.$refs.alert.open({
              type: 'confirm',
              title: `${this.$t('form.success')}`,
              message: `${this.$t('form.congrats')} ${this.deliverable.file_name}`,
              icon: 'success',
              btnAgreeText: 'OK',
            })
          ) {
            this.fetchUsersByDeliverable();
          }
          this.$toast.success(`Successfully shared to ${payload.owner.address}`);
        })
        .catch(err => this.$toast.error(`${payload.owner.address}. ` + err.message))
        .finally(() => {
          this.createOwnerLoading = false;
          this.userShareList = [];
        });
    },
    fetchDeliverableEmailSend(item) {
      const payload = {
        id: this.object_id,
        content_type: this.deliverable.content_type_id,
        email: item,
      };
      this.$store
        .dispatch('DeliverableCenter/sendDeliverableByEmail', payload)
        .then(() => {
          if (
            this.$refs.alert.open({
              type: 'confirm',
              title: `${this.$t('form.success')}`,
              message: `${this.$t('form.congrats')} ${this.deliverable.file_name}`,
              icon: 'success',
              btnAgreeText: 'OK',
            })
          ) {
            this.fetchUsersByDeliverable();
          }
          this.$toast.success(`Successfully shared to ${item}`);
        })
        .catch(() => this.$toast.error(`${item} : ` + 'Invalid Email.'))
        .finally(() => {
          this.createOwnerLoading = false;
          this.loadingShare = false;
          this.userShareList = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.share-button {
  text-transform: none;
  margin-top: -8 px;
}

::v-deep .v-input__append-inner {
  margin-top: 14px;

  .v-input__icon--clear {
    padding-top: 16px;
  }
}

::v-deep .v-select__slot {
  .v-label {
    top: 25px;
    font-size: 14px;
  }

  .v-label--active {
    transform: translateY(-31px) scale(0.75);
  }
}

::v-deep .v-select__selections {
  .v-chip {
    background-color: #dfecf6 !important;
    color: #0060af !important;
    border-radius: 4px;

    .v-chip__content {
      .v-icon {
        color: #0060af !important;
      }
    }
  }
}
</style>
