<template>
  <v-data-table
    dense
    hide-default-footer
    disable-pagination
    :headers="tableHeaders"
    :items="sampleDetailEvaluationOrder"
    :loading="loading"
    class="custom-table type-2"
  >
    <template #[`item.evaluation`]="{value, item}">
      <div class="px-4 py-3">
        <span :class="{ 'b-text': item.package_order || item.sample_section }">{{ value }}</span>
        <span class="c-text" v-if="item.package_order"><br />{{ item.package_order }}</span>
        <span class="c-text" v-if="item.sample_section"><br />{{ item.sample_section }}</span>
      </div>
    </template>
    <template #no-data>
      <BaseNoData :message="$t('content.no_evaluation_data')" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SampleDetailTableEvaluation',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      sampleDetailEvaluationOrder: 'SampleTracking/sampleDetailEvaluationOrder',
    }),
    tableHeaders() {
      return [
        {
          text: this.$t('content.evaluation'),
          value: 'evaluation',
        },
        {
          text: '',
          width: 100,
        },
      ];
    },
  },
};
</script>
