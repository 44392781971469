<template>
  <div v-if="!loading" class="pa-2 p">
    <v-breadcrumbs :items="breadcrumb" class="pl-0 pb-2">
      <template #divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <div class="d-flex align-center mb-4">
      <div class="mr-3">
        <h2 class="i-text-color">
          {{ $t('content.sample', { sample: sampleDetail.idfl_number }) }}
        </h2>
      </div>
      <v-chip
        class="i-text-color ma-2"
        :class="{
          'check-in': isCheckIn,
          'in-testing': isTesting,
          reporting: isReporting,
          invoiced: isInvoiced,
        }"
      >
        {{ $t(statusTitle(sampleDetail.stage)) }}
      </v-chip>
    </div>

    <!-- Sample Detail - Info -->
    <v-card class="pa-4 rounded-lg mb-4" outlined elevation="1">
      <v-card-title class="d-flex pa-0 mb-3 align-center justify-start card-subtitle i-text-color ">
        <SvgIcon icon="icon-sample-info" class="mr-3" />
        {{ $t('content.sample_information') }}
      </v-card-title>

      <v-divider class="mb-5" />

      <v-row>
        <v-col cols="12" md="3">
          <v-img v-if="picture" contain height="250" width="250" class="rounded" :src="picture"> </v-img>
          <div class="d-flex align-center justify-center no-image" v-else>
            <div>
              <SvgIcon icon="no-image" />
              <h4>{{ $t('content.no_image') }}</h4>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="9">
          <v-row v-for="(label, index) in Object.keys(labels)" :key="index">
            <v-col
              cols="12"
              md="4"
              class="d-flex i-title py-0 my-0 i-text-color"
              :class="{
                'align-top py-3': label === 'sample_identification',
                'align-center py-2': label !== 'sample_identification',
              }"
              >{{ labels[label] }}
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="d-flex py-2 i-detail i-text-color"
              :class="{
                'align-top': label === 'sample_identification',
                'align-center': label !== 'sample_identification',
              }"
              >{{ sampleDetail[label] || '-' }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Sample Detail - Buttons -->
      <div class="d-flex flex-column flex-md-row align-center justify-start mt-7">
        <v-btn
          v-for="button in buttons"
          :key="button.id"
          depressed
          height="44px"
          class="white--text br-6 no-transform btn-bg mb-4 mb-md-0 mr-md-3"
          :loading="button.loading"
          :block="$vuetify.breakpoint.smAndDown"
          @click="button.action(button)"
        >
          {{ button.label }}
        </v-btn>
      </div>
    </v-card>

    <!-- Sample Detail - Tabs -->
    <v-tabs
      height="35px"
      v-model="tab"
      :center-active="$vuetify.breakpoint.smAndDown"
      :show-arrows="$vuetify.breakpoint.smAndDown"
    >
      <v-tab class="tabs" v-for="tabs in tabs" :key="tabs">
        {{ tabs }}
      </v-tab>
    </v-tabs>

    <!-- Sample Detail - Tabs Content -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <SampleDetailTableOrder
          :id="id"
          :loading="loadingTestOrders"
          :history="isHistory"
          :withRetest="isReporting || isInvoiced"
          @detail="onDetail"
          @retest="onRetest"
          @compare="onCompare"
        />
      </v-tab-item>
      <v-tab-item>
        <SampleDetailTableEvaluation :loading="loadingEvaluationOrders" />
      </v-tab-item>
      <v-tab-item>
        <SampleDetailTableComparison :loading="loadingComparison" />
      </v-tab-item>
    </v-tabs-items>

    <SampleDetailDialogShipment v-model="dialogShipmentTrackingInfo" />

    <SampleDetailDialogTest
      :dialog="dialogTestDetail"
      :testItems.sync="testItems"
      :viewMode="dialogTestDetailMode"
      @close="dialogTestDetail = false"
    />

    <DocumentViewer
      :dialog="dialogDocument"
      :item.sync="attachmentItem"
      :title="attachmentTitle"
      @close="dialogDocument = false"
    />

    <SampleDetailDialogReportList
      v-model="dialogSampleReport"
      :title="dialogSampleReportTitle"
      :viewFile="dialogSampleReportViewFile"
      :items.sync="sampleReports"
      :disabledShare="dialogSampleReportDisabled"
      @showFile="showFile"
    />

    <SampleDetailDialogRetest v-model="dialogReTest" :testOrder="selectedTestOrder" />

    <BaseAlert ref="alert" />

    <SampleShare :dialog="dialogShare" :idflNumber="sampleDetail.idfl_number" @close="dialogShare = false" />
    <!-- #endregion   -->
  </div>
  <div v-else class="d-flex align-center justify-center loading">
    <v-progress-circular :size="50" :width="7" color="primary" indeterminate />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { utilityMixin, sampleDetailMixin } from '@/mixins';

import DocumentViewer from '@/components/dialog/DocumentViewer';
import SampleShare from '@/components/sample-tracking/SampleShare';
import SampleDetailDialogReportList from '@/components/sample-tracking/SampleDetailDialogReportList';
import SampleDetailDialogTest from '@/components/sample-tracking/SampleDetailDialogTest';
import SampleDetailDialogRetest from '@/components/sample-tracking/SampleDetailDialogRetest';
import SampleDetailDialogShipment from '@/components/sample-tracking/SampleDetailDialogShipment';
import SampleDetailTableOrder from '@/components/sample-tracking/SampleDetailTableOrder';
import SampleDetailTableEvaluation from '@/components/sample-tracking/SampleDetailTableEvaluation';
import SampleDetailTableComparison from '@/components/sample-tracking/SampleDetailTableComparison';

export default {
  name: 'SampleDetail',
  components: {
    DocumentViewer,
    SampleShare,
    SampleDetailDialogReportList,
    SampleDetailDialogTest,
    SampleDetailDialogRetest,
    SampleDetailDialogShipment,
    SampleDetailTableOrder,
    SampleDetailTableEvaluation,
    SampleDetailTableComparison,
  },
  mixins: [utilityMixin, sampleDetailMixin],
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    mode: {
      type: String,
      validator: v => ['active', 'historical'].includes(v),
      default: null,
    },
  },
  data: () => ({
    dialogShipmentTrackingInfo: false,
    dialogDocument: false,
    dialogReTest: false,
    dialogShare: false,
    dialogSampleReport: false,
    dialogSampleReportTitle: '',
    dialogSampleReportViewFile: false,
    dialogSampleReportDisabled: false,
    attachmentItem: {},
    attachmentTitle: '',
    tab: null,
    dialogTestDetail: false,
    dialogTestDetailMode: '',
    testItems: {},
    sampleReports: [],
    selectedTestOrder: {},
    loading: false,
    loadingTestOrders: false,
    loadingEvaluationOrders: false,
    loadingComparison: false,
  }),
  computed: {
    ...mapGetters({
      activeParams: 'SampleTracking/activeParams',
      historicalParams: 'SampleTracking/historicalParams',
      sampleDetail: 'SampleTracking/sampleDetail',
      picture: 'File/picture',
      stages: 'SampleTracking/stages',
    }),
    breadcrumb() {
      return [
        {
          text: this.$t('title.sample_tracking'),
          disabled: false,
          to: { name: 'SampleTracking', query: { tab: this.mode } },
        },
        { text: this.$t('title.sample_detail'), disabled: true },
      ];
    },
    isHistory() {
      return this.mode === 'historical';
    },
    isCheckIn() {
      return this.sampleDetail.stage === 'Checked In';
    },
    isTesting() {
      return this.sampleDetail.stage === 'In Testing';
    },
    isReporting() {
      return this.sampleDetail.stage === 'Reporting';
    },
    isInvoiced() {
      return this.sampleDetail.stage === 'Invoiced';
    },
    labels() {
      return {
        sample_type: this.$t('content.sample_type'),
        courier: this.$t('content.shipped_by'),
        protocol: this.$t('content.protocol'),
        processing_office: this.$t('content.processing_office'),
        processing_office_phone_number: this.$t('content.processing_office_number'),
        sample_identification: this.$t('content.sample_identification'),
        description: this.$t('content.desc_sample'),
      };
    },
    tabs() {
      return [
        this.$t('content.test_orders'),
        this.$t('content.evaluation_orders'),
        this.$t('content.evaluation_comparison_orders'),
      ];
    },
    buttons() {
      return [
        { id: 0, loading: false, label: this.$t('content.submission_form'), action: val => this.viewSubmissionForm(val) },
        { id: 1, loading: false, label: this.$t('content.sample_confirmation'), action: val => this.viewOrderConfirmations(val) },
        { id: 2, loading: false, label: this.$t('content.return_shipment_info'), action: val => this.viewTrackingInfo(val) },
        { id: 3, loading: false, label: this.$t('content.sample_report'), action: val => this.viewDeliverable(val) },
        { id: 4, loading: false, label: this.$t('content.share'), action: val => this.shareSample(val) },
      ];
    },
  },
  // For methods split in sampleDetailMixins.js
  created() {
    this.fetchData();
  },
  destroyed() {
    this.$store.commit('SampleTracking/RESET_SAMPLE_DETAIL');
    this.$store.commit('File/RESET_PICTURE');
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  border: 1px solid #ecebed;
  border-radius: 8px;
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
}

.card-subtitle {
  font-size: 17px;
  font-weight: bold;
  font-family: ArialMT;
}

.br-6 {
  border-radius: 6px;
}

.btn-bg {
  background-color: #0060af !important;
}

.i-text-color {
  color: #16325c !important;
}

.i-title {
  color: #617188 !important;
  font-family: ArialMT;
  font-size: 13px;
}

.i-detail {
  font-family: ArialMT;
  font-size: 15px;
  line-height: 30px;
}

.loading {
  width: 100% !important;
  height: 100vh !important;
  text-align: center;
}

.check-in {
  background: #d6edff !important;
}

.in-testing {
  background: #addcff !important;
}

.reporting {
  background: #85caff !important;
}

.invoiced {
  background: #73acd9 !important;
}

.expansion-title {
  width: 150px;
}

.expansion-panel {
  background-color: #f2f7fb !important;
  color: #16325ccc !important;
}

.no-image {
  border-radius: 8px;
  height: 240px;
  padding: 20px;
  background: #f7f7f3;

  div {
    text-align: center;

    h4 {
      margin-top: 15px;
      color: #6a7b94;
      font-weight: 300;
      font-size: 15px;
    }
  }

  @include media-breakpoint-up(sm) {
    width: 240px;
  }
}

::v-deep .elevation-1 {
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07) !important;
}

::v-deep .v-expansion-panel-content__wrap {
  padding-left: 0px !important;
  font-size: 15px !important;
}
</style>
