<template>
  <BaseDialog
    :dialog="dialog"
    :title="viewMode === 'detail' ? $t('content.test_order_detail') : $t('content.extra_information')"
    @close="close"
  >
    <v-card elevation="0" class="rounded-lg px-3 py-6 mb-5 card-detail">
      <div class="px-4" v-if="viewMode === 'detail'">
        <v-row v-for="(label, index) in Object.keys(labels0)" :key="index">
          <v-col cols="12" md="4" class="txt t-title mn-color py-2">
            {{ $t(labels0[label]) }}
          </v-col>
          <v-col cols="12" md="8" class="txt mn-color py-2">
            <template v-if="label === 'test'">
              <span
                :class="{
                  'b-text': testItems.package_order || testItems.sample_section,
                }"
              >
                {{ testItems[label] || '-' }}
              </span>
              <span class="c-text" v-if="testItems.package_order"><br />{{ testItems.package_order }}</span>
              <span class="c-text" v-if="testItems.sample_section"><br />{{ testItems.sample_section }}</span>
            </template>
            <template v-else-if="label === 'expected_completion_date'">
              {{ utilityMixin_formatDateTime(testItems[label]) || '-' }}
            </template>
            <template v-else>
              {{ testItems[label] || '-' }}
            </template>
          </v-col>
        </v-row>
      </div>
      <div class="px-4" v-else-if="viewMode === 'extra'">
        <v-row v-for="(label, index) in Object.keys(labels1)" :key="index">
          <v-col cols="12" md="4" class="txt t-title mn-color py-2">
            {{ $t(labels1[label]) }}
          </v-col>
          <v-col cols="12" md="8" class="txt mn-color py-2">
            <template v-if="label === 'additional_samples'">
              {{ additionalSamples.length === 0 ? '-' : '' }}
              <div v-for="(item, index) in additionalSamples" :key="index">
                {{ `(${item.sample}) ${item.purpose}` }}
              </div>
            </template>
            <template v-else-if="label === 'options'">
              {{ options.length === 0 ? '-' : '' }}
              <div v-for="(item, index) in options" :key="index">
                {{ item }}
              </div>
            </template>
            <template v-else>
              {{ testItems[label] || '-' }}
            </template>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </BaseDialog>
</template>

<script>
import utilityMixin from '@/mixins/utilityMixin';
import BaseDialog from '@/components/base/BaseDialog';

export default {
  name: 'SampleDetailDialogTest',
  mixins: [utilityMixin],
  components: { BaseDialog },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    testItems: {
      type: Object,
      default: () => ({}),
    },
    viewMode: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    labels0: {
      test: 'content.test_order_name',
      expected_completion_date: 'content.expc_due_date',
    },
    labels1: {
      test_modifications: 'content.test_modification',
      additional_samples: 'content.additional_samples',
      options: 'content.options',
    },
  }),
  computed: {
    additionalSamples() {
      return this.testItems.additional_samples ?? [];
    },
    options() {
      return this.testItems.options ?? [];
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.$emit('update:testItems', {});
    },
  },
};
</script>

<style lang="scss" scoped>
.mn-color {
  color: #16325c;
}

.txt {
  font-family: ArialMT;
  font-size: 15px;

  a {
    text-decoration: none;
    color: #0084f4;
  }
}

.t-title {
  color: #617188 !important;
  font-family: ArialMT;
  font-size: 13px;
}

.card-detail {
  background-color: rgba(223, 236, 246, 0.4);
}

.no-transform {
  text-transform: none;
}

.b-text {
  color: #16325c !important;
  font-weight: 800;
}

.c-text {
  line-height: 25px;
  font-size: 13px;
}
</style>
