<template>
  <div>
    <BaseDialog :dialog="value" :title="title" @close="onClose" width="60%">
      <v-card elevation="0" class="rounded-lg">
        <v-list nav class="px-0">
          <v-list-item v-for="item in items" :key="item.id" class="custom-list mb-4">
            <v-list-item-content>
              <v-list-item-title class="title" v-text="changeName(item.file_name || item.name)" />
            </v-list-item-content>
            <v-list-item-action>
              <div class="d-flex">
                <BaseActionButton v-if="!disabledShare" :tooltipsText="$t('content.share')" @click="onShare(item)">
                  <SvgIcon icon="icon-share" width="20px" height="20px" />
                </BaseActionButton>
                <BaseActionButton
                  :tooltipsText="$t('content.view')"
                  @click="viewFile ? showFile(item) : redirectToDeliverable(item)"
                >
                  <SvgIcon icon="icon-eye" width="20px" height="20px" />
                </BaseActionButton>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </BaseDialog>

    <DeliverableDialogShare :deliverable="selectedItem" :dialog="dialogShare" @close="onCloseDialog" />
  </div>
</template>

<script>
import DeliverableDialogShare from '@/components/deliverable/DelivableDialogShare';

export default {
  name: 'SampleDetailDialogReportList',
  components: { DeliverableDialogShare },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    viewFile: {
      type: Boolean,
      default: false,
    },
    disabledShare: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogShare: false,
    selectedItem: {},
  }),
  methods: {
    redirectToDeliverable(item) {
      const { id, content_type_id: type_id } = item;
      this.$router.push({ name: 'DeliverableCenter', query: { id, type_id } });
    },
    showFile(item) {
      this.$emit('showFile', item);
    },
    onClose() {
      this.$emit('input', false);
      this.$emit('update:items', []);
    },
    onShare(item) {
      this.dialogShare = true;
      this.selectedItem = item;
    },
    onCloseDialog() {
      this.dialogShare = false;
      this.selectedItem = {};
    },
    changeName(input) {
      return input
        .replaceAll('_', ' ')
        .split('.')
        .slice(0, -1)
        .join('.');
    },
  },
};
</script>
